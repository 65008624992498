import styled, { css } from "styled-components";
import bottomBackground from "../../../assets/tingog_signup_bottom_background.png";
import headerMobile from "../../../assets/tingog_signup_header_mobile.png";
import registerHeaderMobile from "../../../assets/bagong_pilipinas_register_header.png";
import { Button } from "antd";
import { ENTITIES } from "constant/entity";

const NavContainer = styled.nav`
  width: 100%;
  height: 3rem;
  padding: 5px 5px;

  .logo {
    display: flex;
    height: 100%;
    justify-content: flex-end;
  }
`;

const RegisterContainer = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 768px) {
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
  }
`;

const RegisterHomePageContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    justify-content: normal;
  }

  .mobile-bg {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0rem;

    @media only screen and (min-width: 320px) {
      bottom: -4rem;
    }

    @media only screen and (min-width: 390px) {
      bottom: 0rem;
    }

    @media only screen and (min-width: 480px) and (orientation: portrait) {
      bottom: -9.5rem;
    }

    @media only screen and (min-width: 768px) {
      bottom: -17.5rem;
    }

    @media only screen and (min-width: 900px) {
      display: none;
    }
  }

  .mobile-gradient {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 2;

    @media only screen and (min-width: 900px) {
      display: none;
    }
  }

  .left-side-container {
    display: flex;

    @media only screen and (min-width: 900px) {
      flex-direction: column;
      position: relative;
      width: 55vw;
    }

    .bp-logo {
      width: 8.5rem;
      margin: 0 auto;
      margin-top: 4rem;
      z-index: 5;

      @media only screen and (min-width: 390px) {
        width: 10rem;
        margin-top: 5.5rem;
      }

      @media only screen and (min-width: 480px) and (orientation: portrait) {
        width: 8.5rem;
        margin-top: 2rem;
        margin-left: 2rem;
      }

      @media only screen and (min-width: 768px) {
        width: 12.5rem;
        margin: 0 auto;
        margin-top: 4rem;
      }
    }

    .bg {
      position: fixed;
      width: 55vw;
      bottom: 0;

      @media only screen and (max-width: 899px) {
        display: none;
      }
    }
  }

  .right-side-container {
    z-index: 99;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 0 1.5rem;
    margin-bottom: 5rem;

    @media only screen and (min-width: 390px) {
      margin-bottom: 7.5rem;
    }

    @media only screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11rem;
    }

    @media only screen and (min-width: 900px) {
      flex-direction: column;
      justify-content: normal;
      gap: 10rem;
      width: 45vw;
      margin: 0 auto;
      padding: 10rem;
      padding-top: 15rem;
      background-color: #2d378c;
    }

    .text-container {
      text-align: left;
      z-index: 99;

      @media only screen and (min-width: 768px) {
        width: 55rem;
      }

      @media only screen and (min-width: 900px) {
        width: 100%;
        display: flex;
      }

      h2,
      strong {
        line-height: 30px;
        font-size: 18px;
        color: #ffffff;

        @media only screen and (min-width: 320px) {
          font-size: 20px;
        }

        @media only screen and (min-width: 768px) {
          font-size: 2rem;
          line-height: 2.5rem;
        }

        @media only screen and (min-width: 900px) {
          font-size: 2.5rem;
          font-size: 2.2vw;
          line-height: 3.25rem;
        }
      }

      h2 {
        font-weight: 300;
      }

      strong {
        font-weight: 600;
      }
    }

    .button-container {
      width: 100%;

      .mobile-register-btn {
        background-color: ${({ theme }) => {
          const { entity } = theme;

          switch (entity) {
            case ENTITIES.AMPING_KANUNAY:
            case ENTITIES.GWAPONG_SERBISYO:
              return theme.colors.primary;
            case ENTITIES.KAUBAN_NATO:
              return theme.colors.secondary;
            case ENTITIES.BPSF:
            case ENTITIES.AKAP_BIGAS:
              return "#2d378c";
          }
        }};
        border: none;
        border-radius: 8px;
        width: 100%;
        height: fit-content;

        @media only screen and (min-width: 900px) {
          display: none;
        }

        span {
          color: #ffffff;

          @media only screen and (min-width: 768px) {
            font-size: 1.35rem;
            padding: 1.15rem;
          }
        }
      }

      .register-btn {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        gap: 5rem;
        height: fit-content;
        z-index: 10;

        @media only screen and (max-width: 899px) {
          display: none;
        }

        span {
          color: #ffffff;
          font-size: 2.5rem;
          font-size: 2.2vw;
          font-weight: 600;
          padding: 0;
        }

        .register-btn-arrow {
          width: 3vw;
          height: auto;
        }
      }
    }

    .gradient {
      width: 45vw;
      position: fixed;
      bottom: -2.5rem;
      z-index: 2;

      @media only screen and (max-width: 899px) {
        display: none;
      }
    }
  }
`;

const RegisterHeader = styled.div`
  width: 100%;
  height: 145px;
  background-image: url(${registerHeaderMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  padding-top: 7rem;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const StepHeader = styled.div`
  width: 100%;
  height: 145px;
  background-image: url(${headerMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const RegisterFooter = styled.div`
  @media only screen and (min-width: 768px) {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-image: url(${bottomBackground});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
  }
`;

const RegisterHomePageFooter = styled.div`
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-image: url(${bottomBackground});
  background-size: cover;
  background-repeat: no-repeat;
`;

const RegisterFormContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const RegisterLogoContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const TermsAndConditionLogoContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 2rem;

  @media only screen and (min-width: 768px) {
    position: initial;
    min-width: 15rem;
    max-width: 20rem;
    padding-top: 0;
  }
`;

const RegisterLogo = styled.img`
  width: 5rem;
  height: auto;
  margin-top: 2.5rem;
  margin-left: 20px;

  @media only screen and (min-width: 768px) {
    width: 8rem;
    margin: 0 auto;
    display: flex;
    margin-top: 5rem;
  }
`;

const UploadImageAvatar = styled.img`
  border-radius: 50%;
  min-height: 130px;
  max-height: 130px;
  object-fit: cover;
  min-width: 130px;
  max-width: 130px;
`;

const UploadButton = styled(Button)``;

const RegisterStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const OtpStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const UploadImageStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const DownloadIdStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 0 20px;
    position: relative;
    z-index: 99;

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header-text {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
          font-size: 2rem;
          font-weight: 600;
        }
      }

      .help-text {
        .help-text-download {
          color: #2d378c;
          font-weight: 700;
        }

        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .id {
      width: 100%;
      @media only screen and (min-width: 768px) {
        max-width: 30rem;
        height: auto;
      }
    }

    .download-id-btn {
      background-color: white;
      font-weight: 600;
      border-radius: 8px;
      padding: 0.5rem 0;
      width: 100%;
      text-align: center;
      cursor: pointer;

      ${({ theme }) => {
        const { colors, entity } = theme;
        let color = colors.primary;

        if (entity === ENTITIES.BPSF || entity === ENTITIES.AKAP_BIGAS) {
          color = colors.secondary;
        }

        return css`
          color: ${color};
          border: 2px solid ${color};
        `;
      }}

      @media only screen and (min-width: 768px) {
        min-width: fit-content;
        max-width: 15rem;
        font-weight: 400;
        border-radius: 4px;

        &:disabled {
          background-color: #e0e0e0;
          color: #000000;
          border-color: #e0e0e0;
        }
      }
    }
  }

  .id-card-download {
    .btn-container {
      display: flex;
      justify-content: center;

      @media only screen and (min-width: 768px) {
        display: none;
      }

      .submit-btn {
        span {
          font-weight: 400;
        }
      }
    }
  }

  .register-again-btn {
    width: 100%;
    min-width: fit-content;
    max-width: 15rem;
    color: #fff;
    font-weight: 400;
    border-radius: 8px;
    padding: 0.5rem 0;
    text-align: center;
    cursor: pointer;

    ${({ theme }) => {
      const { colors, entity } = theme;
      let color = colors.primary;
      let hoverColor = colors.primaryHover;

      if (entity === ENTITIES.BPSF || entity === ENTITIES.AKAP_BIGAS) {
        color = colors.secondary;
        hoverColor = colors.secondaryHover;
      }

      return css`
        background-color: ${color};
        border: 2px solid ${color};

        &:hover,
        &:focus {
          background-color: ${hoverColor};
          border: 2px solid ${hoverColor};
        }
      `;
    }}

    background-color: ${({ theme }) => theme.colors.primary};
    border: 2px solid ${({ theme }) => theme.colors.primary};

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
`;

const LastStepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;

  a {
    color: #000000;
    text-decoration: underline;
  }

  @media only screen and (min-width: 768px) {
    text-align: center;
  }
`;
const RegisterTermsAndConditionContainer = styled.div`
  width: 100%;
  height: 100%;

  .terms-text-container {
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
      padding: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const TermsAndConditionButtonContainer = styled.div`
  box-shadow: 0px -11px 32px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  background-color: ${({ theme }) => {
    const { entity } = theme;

    switch (entity) {
      case ENTITIES.SERBISYONG_LAMI_KAAYO:
      case ENTITIES.TINGOG_47:
      case ENTITIES.MATIK_KANG_TINO:
      case ENTITIES.MARICAR_ZAMORA:
      case ENTITIES.TEAM_ALDU:
        return "#fff";
      case ENTITIES.BPSF:
      case ENTITIES.AKAP_BIGAS:
        return "#2d378c";
      default:
        return theme.colors.primary;
    }
  }};
  position: fixed;
  bottom: 0;
  padding: 0 20px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 100%;

  .checkbox-container {
    display: flex;
    justify-content: center;
    gap: 1rem;

    .agreement-text {
      margin: 0;
      color: ${({ theme }) => {
        const { entity } = theme;

        switch (entity) {
          case ENTITIES.SERBISYONG_LAMI_KAAYO:
          case ENTITIES.TINGOG_47:
          case ENTITIES.MATIK_KANG_TINO:
          case ENTITIES.MARICAR_ZAMORA:
          case ENTITIES.TEAM_ALDU:
            return "#000000";
          default:
            return "#fff";
        }
      }};
      width: fit-content;
    }

    .ant-form-item-control-input {
      display: flex;
      align-items: flex-start;
    }
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    height: 10rem;
    padding: 0;
    padding-left: 20rem;
    justify-content: flex-start;

    .checkbox-container {
      align-items: center;
      gap: 1rem;

      .agreement-text {
        margin-right: 2rem;
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-form-item-control-input {
        align-items: center;
      }
    }
  }
`;

const TermsAndConditionTitle = styled.div`
  display: flex;
  color: #192b8b;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;

  @media only screen and (min-width: 768px) {
    height: 10rem;
    font-size: 2.5vw;
    justify-content: center;
    align-items: center;
  }

  color: ${({ theme }) => {
    const { entity } = theme;

    switch (entity) {
      case ENTITIES.AMPING_KANUNAY:
        return theme.colors.primaryDark;
      case ENTITIES.KAUBAN_NATO:
        return theme.colors.secondary;
      case ENTITIES.SERBISYONG_TINUD_ANAY:
        return theme.colors.secondary;
      case ENTITIES.BPSF:
      case ENTITIES.AKAP_BIGAS:
        return "#192b8b";
      default:
        return theme.colors.primary;
    }
  }};
`;

const TermsAndConditionContent = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 5rem;
  height: calc(100vh - 10rem - 3rem - 145px);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p,
  li,
  ol,
  ul {
    text-align: justify;
    margin: 0;
  }

  @media only screen and (min-width: 768px) {
    width: calc(100% - 1rem);
    height: calc(100vh - 20rem);
    padding-right: 2.5rem;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      background: transparent;
      width: 0.35rem;
    }

    &::-webkit-scrollbar-thumb {
      background: lightgrey;
      border-radius: 10px;
    }
  }
`;

const SummaryHeader = styled.div`
  background-color: #2d378c;
  min-height: 10rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    margin: 1.5rem 0 0;

    span {
      color: #fff;
    }
  }
`;

const SummarySection = styled.div``;

const SummarySectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0;
  color: #2d378c;
  font-weight: 700;
  font-size: 0.9rem;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &.icon {
      color: #2d378c;
    }
  }
  span {
    color: #2d378c;
  }
`;

const SummarySectionContent = styled.div``;
const SummarySectionContentEditable = styled.div``;
const SummarySectionContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 1.5rem;
`;

export {
  NavContainer,
  RegisterContainer,
  RegisterFormContainer,
  RegisterHeader,
  StepHeader,
  RegisterFooter,
  RegisterHomePageContainer,
  RegisterHomePageFooter,
  RegisterLogo,
  RegisterLogoContainer,
  TermsAndConditionLogoContainer,
  UploadImageAvatar,
  UploadButton,
  RegisterStepContainer,
  OtpStepContainer,
  UploadImageStepContainer,
  DownloadIdStepContainer,
  LastStepContainer,
  TermsAndConditionButtonContainer,
  RegisterTermsAndConditionContainer,
  TermsAndConditionTitle,
  TermsAndConditionContent,
  SummaryHeader,
  SummarySection,
  SummarySectionHeader,
  SummarySectionContent,
  SummarySectionContentEditable,
  SummarySectionContentItem,
};
