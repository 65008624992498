/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useReducer, createContext } from "react";
import { Form } from "antd";
import genderOptions from "../../../jsonLib/gender_options.json";
import allProvince from "../../../jsonLib/allProvince.json";
import allMunicipalities from "../../../jsonLib/allMunicipalities.json";
import {
  getBarangayOption,
  sendOTP,
  validateOTP,
  uploadCitizenPhoto,
} from "../../../services/public_registration.service";
import { getCitizenDetails, editCitizenDetails } from "../../../services/citizen_edit.service";
import Swal from "sweetalert2";
import moment from "moment";
import { verifyCitizen } from "../../../services/public_registration.service";

export const CitizenEditContext = createContext({});

const defaultState = {
  citizenDetailsRegister: {},
  citizenDetails: {
    id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    name_extension: "",
    birthdate_year: "",
    birthdate_month: "",
    birthdate_day: "",
    gender: null,
    phone_number: "",
    is_voter: null,
    barangay: null,
    address: "",
    municipality: null,
    email: "",
    province: null,
    profile: "",
    principal: "",
    is_verified: false,
    fb_profile_link: null,
    code: null,
  },
  socialServiceDetails: {
    customer: null,
    service: null,
    status: "pending",
    value_requested: "0",
    has_representative: null,
    other_details: {},
  },
};

const defaultStateFn = key => {
  return key ? defaultState[key] : defaultState;
};

const stateDispatchReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_CITIZEN_DETAILS":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET":
      return defaultState();

    default: {
      if (action.key) {
        if (typeof action.payload === "object") {
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              ...action.payload,
            },
          };
        }

        return {
          ...state,
          [action.key]: action.payload,
        };
      }

      return state;
    }
  }
};

const CitizenEditProvider = ({ children }) => {
  const [state, stateDispatch] = useReducer(stateDispatchReducer, defaultStateFn());
  const [provinceOptions, setProvinceOptions] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [selectedMunicipality, setSelectedMunicipality] = useState();
  const [barangayOption, setBarangayOptions] = useState();
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [messageID, setMessageID] = useState();
  const [otp, setOTP] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [agreement, setAgreement] = useState(true);

  const [form] = Form.useForm();

  function formatPhoneNumber(value) {
    // format the input value as a Philippine phone number
    if (!value) return "";
    value = value.toString();
    if (value.startsWith("0")) return value.replace(/^0/, "63");
    if (value.startsWith("63")) return value;

    // setVal(`63${value}`);
    return `63${value}`;
  }

  function parsePhoneNumber(value) {
    // parse the input value as a number
    if (!value) return 0;
    return value.toString().replace(/^(0|63)/, "");
  }

  useEffect(() => {
    const filteredProvince = allProvince.map(province => ({
      ...province,
      label: province?.name,
      value: province?.id,
    }));

    setProvinceOptions(filteredProvince);
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const filteredMunicipality = allMunicipalities
        .filter(municipality => municipality?.province?.id === selectedProvince)
        .map(municipality => ({
          ...municipality,
          label: municipality?.name,
          value: municipality?.id,
        }));
      form.setFieldsValue({ municipality: undefined });
      setSelectedMunicipality(null);
      setMunicipalityOptions(filteredMunicipality);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedMunicipality) {
      setIsLoading(true);
      setBarangayOptions([]);
      const fetchBarangay = async () => {
        const barangayOption = await getBarangayOption(selectedMunicipality);
        const filteredBarangayOption = barangayOption.results.map(barangay => ({
          ...barangay,
          label: barangay?.name,
          value: barangay?.id,
        }));
        setIsLoading(false);
        setBarangayOptions(filteredBarangayOption);
      };

      fetchBarangay().catch(console.error);
    }
  }, [selectedMunicipality]);

  const checkUserExist = async () => {
    const isUserExist = await verifyCitizen(
      state.citizenDetails.first_name,
      state.citizenDetails.last_name,
      state.citizenDetails.name_extension,
      moment(
        `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
        "YYYY-MM-DD",
        false,
      ).format("YYYY-MM-DD"),
    );
    if (isUserExist) {
      sendOTPMobile();
      const [birthdate_year, birthdate_month, birthdate_day] = isUserExist.birth_date.split("-");
      stateDispatch({
        key: "citizenDetailsRegister",
        payload: {
          ...state.citizenDetailsRegister,
          birthdate_year: birthdate_year,
          birthdate_month: birthdate_month,
          birthdate_day: birthdate_day,
          ...isUserExist,
        },
      });
      stateDispatch({
        key: "citizenDetails",
        payload: {
          ...state.citizenDetails,
          birthdate_year: birthdate_year,
          birthdate_month: birthdate_month,
          birthdate_day: birthdate_day,
          ...isUserExist,
        },
      });
      setSelectedMunicipality(isUserExist.municipality.id);
      setSelectedProvince(isUserExist.municipality.province.id);
      return true;
    } else {
      Swal.fire({
        title: "Error",
        text: "No Records Found",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
    }
    return false;
  };

  const checkAgreement = async () => {
    if (!agreement) {
      Swal.fire({
        title: "Error",
        text: "You must agree to proceed with the changes.",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
      return false;
    }

    const isEditSuccessful = await editCitizenDetail();

    if (!isEditSuccessful) {
      return false;
    }

    return true;
  };

  const transformCitizenDetailsToParams = () => {
    const citizenDetailsToParam = { ...state.citizenDetails };
    delete citizenDetailsToParam.birthdate_year;
    delete citizenDetailsToParam.birthdate_month;
    delete citizenDetailsToParam.birthdate_day;

    citizenDetailsToParam.birth_date = moment(
      `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
      "YYYY-MM-DD",
      false,
    ).format("YYYY-MM-DD");

    citizenDetailsToParam.province = state.citizenDetails.province?.id
      ? state.citizenDetails.province.id
      : state.citizenDetails.municipality?.province?.id || null;

    // Check if municipality and barangay are defined
    citizenDetailsToParam.municipality = state.citizenDetails.municipality?.id || null;
    citizenDetailsToParam.barangay = state.citizenDetails.barangay?.id || null;

    citizenDetailsToParam.gender = state.citizenDetails?.gender?.id
      ? state.citizenDetails.gender.id
      : state.citizenDetails.gender.value || null;

    // convert empty string parameters to `null`
    [
      'fb_profile_link',
      'middle_name'
    ].forEach((fieldName) => {
      if (!citizenDetailsToParam?.[fieldName]) {
        citizenDetailsToParam[fieldName] = null
      }
    })

    return citizenDetailsToParam;
  };

  const editCitizenDetail = async () => {
    if (state.citizenDetails.profile) {
      const params = transformCitizenDetailsToParams();
      const editDetails = await editCitizenDetails(state.citizenDetails.id, params);
      const response = await editDetails.json();
      if (editDetails.status === 400) {
        Swal.fire({
          title: "Oops!",
          text: `${response[0] || response.non_field_errors[0] || "Something went wrong"}`,
          allowOutsideClick: true,
          showCancelButton: true,
          cancelButtonText: "Exit",
          showConfirmButton: false,
          width: "80%",
        });
        return false;
      } else {
        return true;
      }
    }
  };

  const sendOTPMobile = async () => {
    const otp = await sendOTP(state.citizenDetails.phone_number);
    setMessageID(otp?.message_id);
  };

  const verifyOTP = async () => {
    const isValidOTP = await validateOTP(messageID, otp);
    if (!isValidOTP || otp.length < 6 || otp === undefined) {
      Swal.fire({
        title: "Oops!",
        text: "Mali ang iyong nilagay na OTP",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
      return false;
    }
    localStorage.setItem("otp", JSON.stringify({ message_id: messageID, otp_message: otp }));
    // getUserDetails();
    return true;
  };

  const uploadPhoto = async () => {
    const isUpload = await uploadCitizenPhoto(state.citizenDetails.profile);
    return isUpload;
  };
  const payload = {
    state,
    stateDispatchMain: stateDispatch,
    genderOptions,
    provinceOptions,
    municipalityOptions,
    setSelectedProvince,
    setSelectedMunicipality,
    barangayOption,
    selectedProvince,
    selectedMunicipality,
    formatPhoneNumber,
    parsePhoneNumber,
    setIsNumberValid,
    isNumberValid,
    sendOTPMobile,
    messageID,
    setOTP,
    verifyOTP,
    uploadPhoto,
    isLoading,
    agreement,
    setAgreement,
    checkUserExist,
    editCitizenDetail,
    checkAgreement,
    // getUserDetails,
  };

  return <CitizenEditContext.Provider value={payload}>{children}</CitizenEditContext.Provider>;
};

export default CitizenEditProvider;
