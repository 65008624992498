import React, { Suspense, lazy } from "react";

import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("./AmpingKanunay").then(module => ({
      default: module.AmpingKanunay,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("./KaubanNato").then(module => ({
      default: module.KaubanNato,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("./SerbisyongTinudAnay").then(module => ({
      default: module.SerbisyongTinudAnay,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("./SerbisyongLamiKaayo").then(module => ({
      default: module.SerbisyongLamiKaayo,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("./Tingog47").then(module => ({
      default: module.Tingog47,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("components/gwapongSerbisyo/headerWithHoneyboy").then(module => ({
      default: module.HeaderWithHoneyboy,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("components/serbisyongExpress/headerWithMartin").then(module => ({
      default: module.HeaderWithMartin,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("components/oyoUy/headerWithOyo").then(module => ({
      default: module.HeaderWithOyo,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("./MatikKangTino").then(module => ({
      default: module.MatikKangTino,
    })),
  ),
  [ENTITIES.ABTIK_MO_SERBISYO]: lazy(() =>
    import("components/abtikMoSerbisyo/headerWithBernie").then(module => ({
      default: module.HeaderWithBernie,
    })),
  ),
  [ENTITIES.MARICAR_ZAMORA]: lazy(() =>
    import("components/maricarZamora/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.KANATO_INI]: lazy(() =>
    import("components/kanatoIni/headerWithNelson").then(module => ({
      default: module.HeaderWithNelson,
    })),
  ),
  [ENTITIES.IPATUPAD]: lazy(() =>
    import("components/ipatupad/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.TEAM_ALDU]: lazy(() =>
    import("components/teamAldu/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.ALMARIO]: lazy(() =>
    import("components/almario/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
};
const Component = mapping[entity];

export const VideoQrScannerHeader = () => {
  return (
    <Suspense>
      <Component />
    </Suspense>
  );
};
