import React, { Suspense, lazy } from "react";

import { ENTITIES, entity } from "constant/entity";

const mapping = {
  [ENTITIES.AMPING_KANUNAY]: lazy(() =>
    import("components/ampingKanunay/headerWithMigs").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.KAUBAN_NATO]: lazy(() =>
    import("components/kaubanNato/headerWithTopBorder").then(module => ({
      default: module.HeaderWithTopBorder,
    })),
  ),
  [ENTITIES.BPSF]: lazy(() =>
    import("./Bpsf").then(module => ({
      default: module.Bpsf,
    })),
  ),
  [ENTITIES.SERBISYONG_TINUD_ANAY]: lazy(() =>
    import("components/serbisyongTinudanay/headerWithJavi").then(module => ({
      default: module.HeaderWithJavi,
    })),
  ),
  [ENTITIES.AKAP_BIGAS]: lazy(() =>
    import("components/akapBigas/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.SERBISYONG_LAMI_KAAYO]: lazy(() =>
    import("components/serbisyongLamiKaayo/headerWithNonoy").then(module => ({
      default: module.HeaderWithNonoy,
    })),
  ),
  [ENTITIES.TINGOG_47]: lazy(() =>
    import("components/tingog47/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.GWAPONG_SERBISYO]: lazy(() =>
    import("components/gwapongSerbisyo/headerWithHoneyboy").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.SERBISYONG_EXPRESS]: lazy(() =>
    import("components/serbisyongExpress/headerWithMartin").then(module => ({
      default: module.HeaderWithMartin,
    })),
  ),
  [ENTITIES.OYO_UY]: lazy(() =>
    import("components/oyoUy/header").then(module => ({
      default: module.Header,
    })),
  ),
  [ENTITIES.MATIK_KANG_TINO]: lazy(() =>
    import("components/matikkangtino/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.ABTIK_MO_SERBISYO]: lazy(() =>
    import("components/abtikMoSerbisyo/headerWithBernie").then(module => ({
      default: module.HeaderWithBernie,
    })),
  ),
  [ENTITIES.MARICAR_ZAMORA]: lazy(() =>
    import("components/maricarZamora/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.KANATO_INI]: lazy(() =>
    import("components/kanatoIni/headerWithNelson").then(module => ({
      default: module.HeaderWithNelson,
    })),
  ),
  [ENTITIES.IPATUPAD]: lazy(() =>
    import("components/ipatupad/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.TEAM_ALDU]: lazy(() =>
    import("components/teamAldu/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
  [ENTITIES.ALMARIO]: lazy(() =>
    import("components/almario/headerWithAvatar").then(module => ({
      default: module.HeaderWithAvatar,
    })),
  ),
};
const Component = mapping[entity];

export const CitizenDetailHeader = ({ className }) => {
  return (
    <Suspense>
      <Component className={className} />
    </Suspense>
  );
};
