import React from "react";
import styled from "styled-components";
import { Avatar, Col, Divider, Row } from "antd";
import Title from "antd/lib/typography/Title";
import { useSocialServiceContext } from "../Context";
import { PROGRAMS } from "constant/program";
import { formatMoney } from "utils/money";
import { useQrScannerContext } from "pages/QRScan/Context";
import { isProgramVisible } from "utils/citizen";

const Container = styled.div``;
const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .ant-typography {
    margin-bottom: 0;
  }
`;
const CardContainer = styled.div`
  border-radius: 11px;
  position: relative;
  width: 100%;
  height: 60px;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : "")};
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;
const Label = styled.div`
  font-size: 0.9em;
  line-height: 1;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  flex: 0 0 100%;
`;
const Value = styled.div`
  flex: 0 0 100%;
  font-size: 1.5em;
  font-weight: 600;
  ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  span {
    ${({ textColor }) => (textColor ? `color: ${textColor};` : "")};
  }
`;

const logo = {
  [PROGRAMS.AKAP]: "/assets/static/akap-rice.svg",
  [PROGRAMS.MAIP]: "/assets/static/maip.svg",
};

const Card = ({ label, value, bgColor = null, textColor = null }) => {
  return (
    <CardContainer style={{ backgroundColor: bgColor || "inherit" }}>
      <Label style={{ color: textColor || "inherit" }}>{label}</Label>
      <div>
        <Value style={{ color: textColor || "inherit" }}>{value}</Value>
      </div>
    </CardContainer>
  );
};

const ProgramInfo = () => {
  const { citizen } = useSocialServiceContext();
  const { user: agencyUser } = useQrScannerContext();

  const renderPrograms = () => {
    const calculateSum = (codes, field) => {
      return (
        codes?.reduce((acc, code) => {
          if (!isProgramVisible(code, agencyUser?.agency?.id)) return acc;
          acc += code[field];
          return acc;
        }, 0) || 0
      );
    };

    const programs =
      citizen?.codes?.reduce((acc, code) => {
        if (!isProgramVisible(code, agencyUser?.agency?.id)) return acc;
        if (!acc[code.status]) {
          acc[code.status] = [];
        }

        acc[code.status].push(code);

        return acc;
      }, {}) || [];

    const codes = Object.values(programs);

    const renderCodes = () => {
      return codes?.length
        ? codes?.map((code, idx) => {
            const total_subsidy = calculateSum(code, "total_subsidy");

            const total_expenditure = calculateSum(code, "total_expenditure");

            const remaining_balance = calculateSum(code, "remaining_balance");

            const statuses = Object.keys(programs);
            const status = statuses[idx];

            return (
              <Container key={idx}>
                <Header>
                  <Avatar src={logo[agencyUser.overrides.citizen_program?.program?.id]} />
                  <Title level={4}>{agencyUser.overrides.citizen_program?.program?.name}</Title>
                </Header>
                <Divider />
                <Row gutter={15}>
                  <Col span={12}>
                    <Card label="Status" value={status} bgColor="#C8E0FF" textColor="#1D5094" />
                  </Col>
                  <Col span={12}>
                    <Card
                      label="Total Subsidy"
                      value={formatMoney(total_subsidy)}
                      bgColor="#E9EBFF"
                      textColor="#293392"
                    />
                  </Col>
                </Row>
                <Row gutter={15} style={{ marginTop: "15px" }}>
                  <Col span={12}>
                    <Card
                      label="Total Expenditure"
                      value={formatMoney(total_expenditure)}
                      bgColor="#FFEDED"
                      textColor="#E8383B"
                    />
                  </Col>
                  <Col span={12}>
                    <Card
                      label="Remaining Balance"
                      value={formatMoney(remaining_balance)}
                      bgColor="#EBFFF8"
                      textColor="#1A6E50"
                    />
                  </Col>
                </Row>
                <Divider />
              </Container>
            );
          })
        : null;
    };

    return renderCodes();
  };

  return <>{renderPrograms()}</>;
};

export default ProgramInfo;
