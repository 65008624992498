import { compressImage } from "utils";
import { ROOT_URL } from "../config";
import { ENTITIES_ID, entity } from "constant/entity";

const verifyMobileNumber = async phone_number => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/is_contact_used/?phone_number=${phone_number}`, {
      method: "GET",
    });
    if (response.status === 200) {
      return await response.json();
    }
  } catch (Exception) {
    console.log(Exception);
  }
};

const getBarangayOption = async municipalityID => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/barangay/?municipality=${municipalityID}`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getATC = async municipalityID => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/atc/?municipality_id=${municipalityID}`, {
      method: "GET",
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export const getEntityId = () => ENTITIES_ID[entity];

const generateIDCard = async citizen_id => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/${citizen_id}/view_card`, {
      headers: {
        "Content-Type": "image/png",
      },
      method: "GET",
    });

    return await response.blob();
  } catch (Exception) {
    console.error(Exception);
  }
};

const viewIDCard = async citizen_id => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/${citizen_id}/view_card/?view_only=1`, {
      headers: {
        "Content-Type": "image/png",
      },
      method: "GET",
    });

    return await response.blob();
  } catch (Exception) {
    console.error(Exception);
  }
};

const verifyCitizen = async (first_name, last_name, suffix, birth_date) => {
  try {
    const response = await fetch(
      ROOT_URL +
        `/citizen/is_citizen_existing/?first_name=${first_name}&last_name=${last_name}&name_extension=${suffix}&birth_date=${birth_date}`,
      {
        method: "GET",
      },
    );
    if (response.status === 200) {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const sendOTP = async phone_number => {
  let formData = new FormData();
  formData.append("phone_number", phone_number);
  formData.append("app", getEntityId());
  try {
    const response = await fetch(ROOT_URL + "/core/otp/send", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const validateOTP = async (message_id, otp) => {
  let formData = new FormData();
  formData.append("message_id", message_id);
  formData.append("otp", otp);
  try {
    const response = await fetch(ROOT_URL + "/core/otp/validate", {
      method: "POST",
      body: formData,
    });

    if (response.status === 200) {
      return await response.json();
    }

    return false;
  } catch (Exception) {
    console.error(Exception);
  }
};

const registerCitizen = async citizenDetails => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/", {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify({
        ...citizenDetails,
        app: getEntityId(),
      }),
    });

    return response;
  } catch (Exception) {
    console.error(Exception);
  }
};

const registerCitizenSocialService = async socialServiceDetails => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/social_service/", {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(socialServiceDetails),
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const uploadCitizenPhoto = async photo => {
  let formData = new FormData();
  const compressedPhoto = await compressImage(photo);
  formData.append("photo", compressedPhoto);
  try {
    const response = await fetch(ROOT_URL + "/citizen/upload_photo", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const addBarangay = async (name, municipalityID) => {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("municipality", municipalityID);
  try {
    const response = await fetch(ROOT_URL + "/citizen/barangay/", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

export {
  verifyMobileNumber,
  getBarangayOption,
  sendOTP,
  registerCitizen,
  validateOTP,
  verifyCitizen,
  getATC,
  generateIDCard,
  viewIDCard,
  registerCitizenSocialService,
  uploadCitizenPhoto,
  addBarangay,
};
