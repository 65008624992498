import { message, Row } from "antd";
import { format } from "date-fns";
import { useQrScannerContext } from "pages/QRScan/Context";
import React, { useState } from "react";
import styled from "styled-components";
import redeem from "assets/redeemcheck.svg";
import StyledButton from "components/button/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { redeemSocialService } from "services/citizen";
import { handleApiValidationErrors } from "utils/api";
import Swal from "sweetalert2";

const Container = styled.div`
  padding-right: 4rem;
  padding-left: 3rem;
  padding-top: 3rem;
`;

const ProgramSection = styled(Row)`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
`;

const AgencyName = styled.h1`
  font-weight: bold;
`;

const EligibleText = styled.p`
  text-align: center;
  color: #1cc360;
  margin-top: 3rem;
`;

const RedeemSection = styled(ProgramSection)`
  margin-top: 3rem;
  flex-direction: column;
`;

const RedeemText = styled.h3`
  font-weight: bold;
  color: #1cc360;
`;

const RedeemIcon = styled.img`
  margin-bottom: 1rem;
`;

const RedeeemBtn = styled(StyledButton)`
  background-color: #1cc360;
  height: 43px;
  border: none;
  width: 250px;

  &:hover {
    background: #1cc360;
  }
`;

const BackButton = styled(StyledButton)`
  height: 43px;
  border-radius: 11px;
  background: rgba(217, 217, 217, 0.21);
  width: 250px;
  margin-top: 1rem;

  span {
    color: initial;
  }
  @media only screen and (min-width: 768px) {
    background: white;
    span {
      color: initial;
    }
  }
`;

const BtnsSection = styled(ProgramSection)`
  margin-top: 2rem;
  align-items: center;
`;

const Eligible = () => {
  const { id } = useParams();
  const { user: agencyUser } = useQrScannerContext();

  const [isRedeemed, setIsRedeemed] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const handleOnClickRedeem = () => {
    const citizenProgram = agencyUser?.overrides?.citizen_program;

    if (citizenProgram) {
      redeemSocialService({
        citizen: id,
        agency_user: agencyUser?.id || null,
        agency: agencyUser?.agency?.id || null,
        overrides: citizenProgram?.overrides || {},
        program: citizenProgram.program.id,
        program_type: citizenProgram.program_type.id,
        proposed_amount: citizenProgram.proposed_amount,
      })
        .then(response => {
          if (response.ok) {
            setIsRedeemed(!isRedeemed);
          } else if (response.status === 400) {
            handleApiValidationErrors(response);
          }
        })
        .catch(() => {
          Swal.fire({
            title: "An Error Occured",
            text: "There was a problem redeeming this program. Try again later",
            icon: "error",
            confirmButtonText: "Back",
            width: "80%",
          }).then(res => {
            if (res.isConfirmed) {
              navigate("/scan");
            }
          });
        });
    } else {
      messageApi.error({
        key: "quickRedeem",
        content: "Citizen program data is missing",
        style: {
          fontWeight: 500,
        },
      });
      setTimeout(() => {
        navigate("/scan");
      }, 1500);
    }
  };

  return (
    <Container>
      {contextHolder}
      <ProgramSection>
        <AgencyName>{agencyUser?.agency?.acronym}</AgencyName>
        <h2>{agencyUser?.overrides?.citizen_program?.program?.name}</h2>
      </ProgramSection>

      {isRedeemed && (
        <RedeemSection>
          <RedeemIcon src={redeem} />
          <RedeemText>Redeemed</RedeemText>
          {format(new Date(), "MMMM dd, yyyy h:mm a")}
        </RedeemSection>
      )}

      {!isRedeemed && <EligibleText>This user is eligible for this program</EligibleText>}
      <BtnsSection>
        {!isRedeemed && <RedeeemBtn onClick={() => handleOnClickRedeem()}>Mark as Redeeemed</RedeeemBtn>}
        <Link to={-1}>
          <BackButton>Back</BackButton>
        </Link>
      </BtnsSection>
    </Container>
  );
};

export default Eligible;
